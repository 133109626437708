<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
        <ul>
            <li v-for="(errors, field) in validation_errors" :key="field">
                {{ field }}
                <ul>
                    <li v-for="(error, sl) in errors" :key="sl">
                        {{ error }}
                    </li>
                </ul>
            </li>
        </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2"
                            >User Entry</v-toolbar-title
                        >
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form
                            @submit.prevent="saveUser"
                            ref="userForm"
                            class="custom-form"
                        >
                            <v-row>
                                <v-col cols="5" offset="1" xs="12">
                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Name
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :rules="[(v) => !!v]"
                                                v-model.trim="user.name"
                                                :error-messages="validation_errors.name"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Username
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :rules="[(v) => !!v]"
                                                v-model.trim="user.username"
                                                :error-messages="validation_errors.username"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Employee ID
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model.trim="user.employee_id"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="5" xs="12">
                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Role
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-select
                                                dense
                                                outlined
                                                hide-details
                                                :items="['Admin', 'User', 'Entry User']"
                                                :rules="[(v) => !!v]"
                                                v-model="user.role"
                                                :error-messages="validation_errors.name"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Password
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :type="show ? 'text' : 'password'"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                v-model.trim="user.password"
                                                :error-messages="validation_errors.password"
                                                @click:append="show = !show"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="py-1 caption text-right">
                                            Confirm Password
                                        </v-col>
                                        <v-col cols="7" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :type="show_confirm ? 'text' : 'password'"
                                                :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                                v-model.trim="user.password_confirmation"
                                                @click:append="show_confirm = !show_confirm"
                                                :rules="[ v => v === this.user.password]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="text-right">
                                            <v-btn
                                                type="submit"
                                                height="26px"
                                                dark
                                                color="light-blue darken-2"
                                                :elevation="1"
                                                class="mr-1"
                                                :loading="loading"
                                                >Save</v-btn
                                            >
                                            <v-btn
                                                height="26px"
                                                dark
                                                color="deep-orange"
                                                :elevation="1"
                                                @click="resetForm"
                                                >Cancel</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="userHeaders"
                    :items="$store.getters['user/getUsers']"
                    :search="searchUser"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >User List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search User"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchUser"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editUser(item)"
                                    color="primary"
                                    v-on="on"
                                    style="margin-right: 5px;"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="deleteUser(item)"
                                    color="error"
                                    v-on="on"
                                    style="margin-right: 5px;"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin' && item.role != 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="userAccess(item.id)"
                                    color="info"
                                    v-on="on"
                                    >mdi-account-group-outline</v-icon
                                >
                            </template>
                            <span>Access</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "Users",
    mixins: [utility],
    data() {
        return {
            show_confirm: false,
            show        : false,
            userHeaders : [
                { text: "Name", value: "name" },
                { text: "Username", value: "username" },
                { text: "Employee ID", value: "employee_id" },
                { text: "Role", value: "role" },
                { text: "Action", value: "action" },
            ],
            searchUser: "",
            user      : {
                name                 : "",
                username             : '',
                employee_id          : '',
                role                 : 'User',
                password             : '',
                password_confirmation: '',
            },
            editId           : null,
            validation_errors: {},
            loading          : false,
        };
    },
    created() {
        this.$store.dispatch("user/getUsers");
    },
    methods: {
        async saveUser() {
            if(!this.$refs.userForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }
            if(this.user.password == '' && this.editId == null){
                alert('Password required');
                return;
            }
            this.loading = true;

            let data = {
                url: "/add-user",
                user: this.user,
            };

            if (this.editId) {
                data.url = "/update-user";
                data.user.id = this.editId;
            }

            let resObj = await this.$store.dispatch("user/saveUser", data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if(resObj.isSuccess) {
                this.resetForm();
            }

        },
        editUser(user) {
            Object.keys(this.user).forEach(
                (k) => (this.user[k] = user[k])
            );
            this.user.password              = '';
            this.user.password_confirmation = '';
            this.editId                     = user.id;
        },
        deleteUser(user) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("user/deleteUser", user.id);
        },
        userAccess(id){
            this.$router.push(`/user_access/${id}`);
        },
        resetForm() {
            Object.keys(this.user).forEach(
                (k) => (this.user[k] = "")
            );

            this.user.role = 'User';

            this.editId = null;
            this.$refs.userForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style>
.custom-form .v-input__slot .v-label{
    top: 0 !important;
}
</style>
